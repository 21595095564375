import React from "react"
import { graphql } from "gatsby"
import slug from "slug"
import styles from "../pages/page.module.scss"
import Title from "../components/Title"
import NavigationLink from "../components/NavigationLink"
import QuoteForm from "../components/QuoteForm"
import WhatsappContact from "../components/WhatsappContact"

const createMarkup = content => {
  return { __html: content }
}

const ServiceTemplate = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title, whatsappUrl, featured, category },
      html,
    },
  } = data

  return (
    <div className={styles.container}>
      <div className={styles.headBar} />
      <div className={styles.titleContainer}>
        <Title>{title}</Title>
        <NavigationLink
          to={`/servicios/${slug(category.toLowerCase())}`}
          text={`Explora todos los servicios de ${category}`}
        />
      </div>
      <div className={styles.serviceContent}>
        <div className={styles.serviceContentImg}>
          <img src={featured} alt={title} />
        </div>
        <div
          className={styles.serviceContetTxt}
          dangerouslySetInnerHTML={createMarkup(html)}
        />
      </div>

      <hr />

      {whatsappUrl && (
        <div className={styles.waContainer}>
          <WhatsappContact
            url={whatsappUrl}
            text={"Solicita más información"}
          />

          {/*
          <p>Ó, déjanos tus datos y nos comunicamos contigo</p>
          */}
        </div>
      )}
      {/*
      <QuoteForm />
      */}
    </div>
  )
}

export const query = graphql`
  query Service($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        featured
        category
        whatsappUrl
      }
      html
    }
  }
`

export default ServiceTemplate
