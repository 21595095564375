import React from "react"
import whatsappStyles from "./whatsapp.module.scss"
import whatsapp from "../assets/whatsapp.svg"

const WhatsappContact = ({ url, text }) => (
  <a className={whatsappStyles.main} href={url}>
    <img src={whatsapp} />
    <p>{text}</p>
  </a>
)

export default WhatsappContact
